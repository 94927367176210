































































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Trainer } from '@/api/interfaces/trainer'
import TopBar from '@/components/organisms/o-top-bar.vue'
import MFilterList from '@/components/molecules/m-filter-list.vue'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { Area } from '@/api/interfaces/area'
import { userConnectedCanAccess } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canAddTrainer = () => userConnectedCanAccess('Trainer Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.trainerList.totalItems
    }
    const filters = ref({
      firstName: query.firstName || '',
      lastName: query.lastName || '',
      areaSearched: query.areaSearched || '',
      trainingTypeSearched: query.trainingTypeSearched || '',
    })
    delete query.firstName
    delete query.lastName
    delete query.areaSearched
    delete query.trainingTypeSearched
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canAddTrainer,
    }
  },
  name: 'TrainerList',
  components: {
    MFilterList,
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      title: this.$t('trainer.list.title_header'),
      headers: [
        {
          text: this.$t('trainer.list.headers.lastName'),
          align: 'start',
          value: 'lastName',
        },
        {
          text: this.$t('trainer.list.headers.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('trainer.list.headers.phoneNumber'),
          value: 'phoneNumber',
          sortable: false,
        },
        {
          text: this.$t('trainer.list.headers.email'),
          value: 'email',
        },
        {
          text: this.$t('trainer.list.headers.area'),
          value: 'areas',
          sortable: false,
        },
        {
          text: this.$t('trainer.list.headers.needTrainings'),
          value: 'trainingType',
          sortable: false,
        },
        {
          text: this.$t('trainer.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('trainerList', {
      trainerList: 'list',
      loading: 'loading',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapGetters('trainingType', {
      trainingTypesList: 'getListActiveItems',
    }),
    ...mapState('filterList', ['filter']),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        lastName: this.filters.lastName,
        firstName: this.filters.firstName,
        areas: this.filters.areaSearched,
        trainingType: this.filters.trainingTypeSearched,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('trainerList', {
      load: 'load',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )
      router.replace({ name: 'Trainer List', query: query })
    },
    gotToTrainerForm (item: Trainer) {
      if (item.id) {
        this.$router.push({
          name: 'Trainer Edit',
          params: { idTrainer: item.id.toString() },
        })
      }
    },
    concatAreas (areas: Array<Area>): string {
      let areasString = ''
      areas.forEach((area: Area): void => {
        areasString += area.name + ', '
      })
      return areasString.slice(0, -2)
    },
    concatTrainingTypesNames (trainingTypes: Array<TrainingType>): string {
      let trainingTypesString = ''
      trainingTypes.forEach((trainingType: TrainingType): void => {
        trainingTypesString += `${trainingType.name}, `
      })
      return trainingTypesString.slice(0, -2)
    },
    filterByLastName (value: string) {
      this.filters.lastName = value
    },
    filterByFirstName (value: string) {
      this.filters.firstName = value
    },
    filterByArea (value: string) {
      this.filters.areaSearched = value
    },
    filterByTrainingType (value: string) {
      this.filters.trainingTypeSearched = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Trainer Edit' || to.name === 'Trainer Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
